import React from 'react'
import ReactDOM from 'react-dom'
import ReactTags from 'react-tag-autocomplete'

class Tags extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      tags: [
        { id: 1, name: "Apples" },
        { id: 2, name: "Pears" }
      ],
      suggestions: [
        { id: 3, name: "Bananas" },
        { id: 4, name: "Mangos" },
        { id: 5, name: "Lemons" },
        { id: 6, name: "Apricots" }
      ]
    }

    this.reactTags = React.createRef()
  }

  onDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }

  onAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
  }

  render () {
    return (
      <ReactTags
        ref={this.reactTags}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete.bind(this)}
        onAddition={this.onAddition.bind(this)} />
    )
  }
}

 
export default Tags

// import React, { useState, useEffect, createRef } from "react";
// import ReactDOM from 'react-dom'
// import ReactTags from 'react-tag-autocomplete'
 

// const Tags = () => {

// const reactTags = createRef()
//   const [tags, update_tags] = useState([
//     { id: 1, name: "Apples" },
//     { id: 2, name: "Pears" }
//   ]);
//   const [suggestions, upupdate_suggestions] = useState([
//     { id: 3, name: "Bananas" },
//     { id: 4, name: "Mangos" },
//     { id: 5, name: "Lemons" },
//     { id: 6, name: "Apricots" }
//   ]);
//   function onDelete (i) {
//     const new_tags = [...tags].slice(0)
//     new_tags.splice(i, 1)
//     update_tags({ new_tags })
//   }

//   function onAddition (tag) {
//     const new_tags = [].concat(tags, tag)
//     update_tags({ new_tags })
//   }

//   return (
//     <ReactTags
//       ref={reactTags}
//       tags={tags}
//       suggestions={suggestions}
//       onDelete={onDelete()}
//       onAddition={onAddition()} />
//   )
// }

// export default Tags

 