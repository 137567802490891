import React from "react";
import Layout from "../../components/layout";
import Tags from "../../components/tags";

const Index = () => {
  return (
    <div>
      <Layout inline={true}>
        <Tags />
      </Layout>
    </div>
  );
};

export default Index;
